<template>
    <div class="ks-emails ks-compact d-flex flex-direction-column">
        <div class="ks-header">
            <h1>Create CRF</h1>
        </div>
        
       <CRFdetails/>
    </div>


</template>

<script>

import CRFdetails from '@/views/crf/crf_details.vue'

export default {
    data(){
        return {
          
        }
    },
   components: {
        CRFdetails
   },
}
</script>